<template>
  <nav class="navbar navbar-expand-lg navbar-light ">
    <div class="container">
      <div class="header-nav">
        <router-link id="left" to="/">
          <img
            v-if="info"
            class="home-logo"
            :src="info.logo"
            :alt="info.alt"
          />
        </router-link>
        <button
          id="right"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li>
            <router-link to="/">{{ $t("layout.nav.home") }}</router-link>
          </li>
          <li>
            <router-link to="/nosotros">{{
              $t("layout.nav.about.title")
            }}</router-link>
          </li>
          <li>
            <router-link to="/propiedades">{{
              $t("layout.nav.properties")
            }}</router-link>
          </li>
          <li>
            <router-link to="/desarrollos">{{
              $t("layout.nav.developments")
            }}</router-link>
          </li>
          <li>
            <router-link to="/agentes">{{
              $t("layout.nav.agents")
            }}</router-link>
          </li>
          <li>
            <router-link to="/contacto">{{
              $t("layout.nav.contact")
            }}</router-link>
          </li>
          <li>
            <LocaleSwitcher />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
  components: {
    LocaleSwitcher,
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
.home-logo {
  width: 5vw;
}
@media only screen and (max-width: 991px) {
  .home-logo {
    width: 15%;
  }
  
#left {
  float: left;
  width: 60%;
}

#right {
  float: right;
}
}

@media (min-width: 768px) {
  .navbar-nav li {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.nav > li {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.navbar-nav li {
  color: #333 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 15px;
  text-decoration: none;
}

.navbar-nav li:hover {
  color: #00aeef !important;
}

a {
  color: #333 !important;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s ease 0s;
}

a:hover {
  color: #138cca !important;
}

.navbar {
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0;
}

.navbar-light .navbar-toggler {
  border-color: none !important;
}

.collapse {
  margin-left: 35%;
}



</style>
