<template>
  <div :class="[(nameRoute!='Agent')?'col-lg-4':'col-lg-12',  'mb-4']">
    <div class="single_team">
      <div class="img-agent">
        <div class="gotoAgent">
          <button
          v-if="nameRoute!='Agent'"
          @click="goToAgent()" 
          class="btn"
          type="submit">
            {{ $t("components.agent_card.link") }}
          </button>
        </div>
        <img
          v-if="agent"
          :src="agent.image ? agent.image : '/images/avatar.png'"
          :alt="`${agent.fullname}-img`"
          class="img-responsive"
        />
      </div>

      <h3 v-if="agent.fullname">{{agent.fullname}}</h3>
      <p><a v-if="agent.cellphone" :href="'tel:' + agent.cellphone" target="_blank"> {{ agent.cellphone }}</a>  </p>
      <p><a v-if="agent.email" :href="'mailto:' + agent.email" target="_blank"> {{ agent.email }}</a> </p>
      <!-- <p>Co Founder</p> -->
      <!-- <ul class="list-inline">
        <li v-if="agent.tweet">
          <a :href="agent.tweet" target="_blank"><i class="fa fa-twitter"></i></a>
        </li>
        <li v-if="agent.fb">
          <a :href="agent.fb"><i class="fa fa-facebook"></i></a>
        </li>
        <li v-if="agent.linkedin">
          <a :href="agent.linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
        </li>
        <li v-if="agent.instagram">
          <a :href="agent.instagram" target="_blank"><i class="fa fa-instagram"></i></a>
        </li>
        <li v-if="agent.gmas">
          <a :href="agent.gmas"><i class="fa fa-google-plus"></i></a>
        </li>
        <li v-if="agent.skypeid">
          <a :href="agent.skypeid"><i class="fa fa-skype"></i></a>
        </li>
      </ul> -->
      <ul class="list-inline ">
        <li >
          <a :href="agent.tweet" target="_blank"><i class="fa fa-twitter"></i></a>
        </li>
        <li>
          <a :href="agent.fb"><i class="fa fa-facebook"></i></a>
        </li>
        <li >
          <a :href="agent.linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
        </li>
        <li >
          <a :href="agent.instagram" target="_blank"><i class="fa fa-instagram"></i></a>
        </li>
        <li >
          <a :href="agent.youtube" target="_blank"><i class="fa fa-youtube-play"></i></a>
        </li>
        <li >
          <a :href="agent.gmas"><i class="fa fa-google-plus"></i></a>
        </li>
        <li >
          <a :href="agent.skypeid"><i class="fa fa-skype"></i></a>
        </li>
      </ul>
    </div>
    <!--- END SINGLE TEAM -->
  </div>
  <!--- END COL -->
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    agent: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formatBiography() {
      return this.agent.biografia.substring(0, 125) + "...";
    },
    nameRoute() {
      return this.$route.name;
    },
  },
  methods: {
    goToAgent() {
      //this.$store.dispatch('setAgentActive',this.agent);
      let nameAgentFilt = services.normalize(this.agent.fullname);
      let folio = this.agent.folio;
      this.$router.push({
        name: "Agent",
        params: {
          folio,
          nameAgentFilt,
        },
      });
    },
  },
};
</script>


<style scoped>
  .img-responsive{
    width: 100% !important;
    height: 255px !important;
    object-fit: contain;
  }

a{
  color: #373737;
}

a:hover{
  color: #4c8aaa;
}
.img-agent{
  position: relative;
  display: block;
}
.gotoAgent{
  position: absolute;bottom: 0px;right: 0px;z-index: 8;
}
.gotoAgent button{
  display: block;
  margin-bottom: 5px;
  padding: 4px 8px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-size: 10px;
  border-radius: 4px;
  background: #138ccacf !important;
}
</style>